<script>
import ProfileSidebar from "./ProfileSidebar";
import {mapState} from "vuex";

export default {
  page: {
      title: 'Λογαριασμός',
  },
  components: {ProfileSidebar},
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    }),
    canUpdate(){

      return this.oldPassword && this.newPassword && this.repeatNewPassword;
    }
  },
  data() {
    return {
        oldPassword: null,
        newPassword: null,
        repeatNewPassword: null,
        updateError: null,
        updateErrorMsg: '',
        updatingPassword: false
    }
  },
  methods:{
    changeUserPassword(){
      this.updateError = null;
      if(this.newPassword !== this.repeatNewPassword){
          this.updateError = true;
          this.updateErrorMsg = 'Οι κωδικοί πρόσβασης δεν ταιριάζουν.';
          return null;
      }
      this.updatingPassword= true;

      this.$axios.put(`/users/change-password`, {oldPassword: this.oldPassword, newPassword: this.newPassword}).then(result => {
          this.oldPassword = '';
          this.newPassword = '';
          this.repeatNewPassword = '';
          this.updatingPassword=false;
          this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Ο κωδικός άλλαξε επιτυχώς!'});

      }).catch(error => {
          this.updatingPassword= false;
          this.updateError=true;
          if(error.response) this.updateErrorMsg = error.response.data.message;
      });
  },


  }
}
</script>
<template>
    <div id="change-password-layout">
        <div class="row">
            <ProfileSidebar></ProfileSidebar>
            <b-card class="col-lg-9 col-xl-9">
                    <b-card-title>Αλλαγή Κωδικού Πρόσβασης</b-card-title>
                    <b-card-sub-title>Αλλάξτε τον κωδικό σύνδεσής σας στην εφαρμογή</b-card-sub-title>

              <div class="mt-4">
                <b-overlay :show="updatingPassword" rounded="sm">
                  <b-form @submit.prevent="changeUserPassword">
                    <div class="row">
                      <b-form-input v-model="user.email" class="d-none" autocomplete="username"></b-form-input>
                      <div class="col-12">
                        <b-form-group id="input-1" label="Τωρινός κωδικός πρόσβασης" label-for="password" >
                          <b-form-input id="password" autocomplete="current-password" v-model="oldPassword" type="password" placeholder="Εισάγετε τον κωδικό πρόσβασής σας" required></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group id="input-2"  label="Νέος κωδικός πρόσβασης" label-for="new-password">
                          <b-form-input v-model="newPassword"  id="new-password" autocomplete="new-password" type="password" placeholder="Εισάγετε τον νέο κωδικό πρόσβασής σας." required></b-form-input>
                        </b-form-group>
                      </div>

                      <div class="col-md-6">
                        <b-form-group id="input-3" label="Νέος κωδικός πρόσβασης" label-for="repeat-new-password">
                          <b-form-input id="repeat-new-password" v-model="repeatNewPassword" autocomplete="new-password" type="password" placeholder="Επαναλάβετε τον νέο κωδικό πρόσβασής σας." required></b-form-input>
                        </b-form-group>
                      </div>

                    </div>

                    <div class="mt-2 text-right">
                      <save-button type="submit" text="Αλλαγή κωδικού πρόσβασης" class="mt-2 text-right" :disabled="!canUpdate"></save-button>
                    </div>



                    <b-alert v-model="updateError" variant="danger" dismissible>
                      {{updateErrorMsg}}
                    </b-alert>

                  </b-form>
                </b-overlay>
              </div>


                <!-- end card-box-->
            </b-card>
            <!-- end col -->
        </div>
        <!-- end row-->
    </div>
</template>
